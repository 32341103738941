import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

function Post (props) {
    return (
	<div>
	    <h1>{props.title}</h1>
	    <em>by <strong>{props.username}</strong></em>
	    <br />
	    <p>{props.content.content}</p>
	    <br />
	    <br />
	</div>
    );
}

var body;

fetch("https://ts-worker.kgurazad.workers.dev/post")
    .then(function (res) {
	if (res.status === 200) {
	    res.json().then(function (posts) {
		for (var post of posts) {
		    // the api sends the posts in reverse order
		    // so i can go in normal order here
		    body = React.createElement(React.Fragment, null, body, React.createElement(Post, post));
		}
		ReactDOM.render(
		    body,
		    document.getElementById('root')
		);		
	    });
	}
    });

				

